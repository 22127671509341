@import "~slick-carousel/slick/slick.scss";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";

html {
    font-size: 15px;
}
body {
    font-size: 16px;
}

.grecaptcha-badge {
    display: none !important;
}
